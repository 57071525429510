import React, { useState, useCallback, useEffect, useRef } from 'react';
import Webcam from 'react-webcam';
import { useNavigate, useParams } from 'react-router-dom';
import { FaceDetection } from '@mediapipe/face_detection';
import { Camera } from '@mediapipe/camera_utils';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, RadioGroup, FormControlLabel, Radio ,styled} from '@mui/material';

const WebcamComponent = ({ fetchProducts }) => {
  const navigate = useNavigate();
  const { restaurantId, tableNo } = useParams();
  const [permissionGranted, setPermissionGranted] = useState(false);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [isFaceDetected, setIsFaceDetected] = useState(false);
  const [preference, setPreference] = useState('VEG');
  const [open, setOpen] = useState(false);
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  const CustomRadio = styled(Radio)(({ theme }) => ({
    '&.Mui-checked': {
      color: '#3cba79',
    },
  }));

  const handlePreferenceChange = (event) => {
    setPreference(event.target.value);
  };

  const handleDialogClose = async () => {
    setOpen(false);
    await fetchProducts(capturedImage, restaurantId, tableNo, preference);
    navigate(`/${restaurantId}/${tableNo}/captured-image`, { state: { imageData: capturedImage } });
    setIsLoading(false);
  };

  const capture = useCallback(async () => {
    if (isFaceDetected) {
      setIsLoading(true);
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedImage(imageSrc);
      setOpen(true);
    }
  }, [webcamRef, isFaceDetected]);

  const checkAndRequestPermission = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach(track => track.stop());
      setPermissionGranted(true);
      setPermissionDenied(false);
    } catch (error) {
      console.error('Webcam permission not granted yet.');
      setPermissionDenied(true);
      setTimeout(checkAndRequestPermission, 1000); // Check again after 1 second
    }
  }, []);

  useEffect(() => {
    checkAndRequestPermission();
  }, [checkAndRequestPermission]);

  useEffect(() => {
    if (permissionGranted && webcamRef.current && canvasRef.current) {
      const faceDetection = new FaceDetection({
        locateFile: (file) => `https://cdn.jsdelivr.net/npm/@mediapipe/face_detection/${file}`,
      });

      faceDetection.setOptions({
        model: 'short',
        minDetectionConfidence: 0.5,
      });

      faceDetection.onResults(onResults);

      const videoElement = webcamRef.current.video;
      if (videoElement) {
        const camera = new Camera(videoElement, {
          onFrame: async () => {
            await faceDetection.send({ image: videoElement });
          },
          width: 640,
          height: 480,
        });
        camera.start();
      }
    }
  }, [permissionGranted, webcamRef, canvasRef]);

  const onResults = (results) => {
    if (!canvasRef.current) return;

    setIsFaceDetected(results.detections.length > 0);

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext('2d');
    canvasCtx.save();
    canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
    canvasCtx.drawImage(results.image, 0, 0, canvasElement.width, canvasElement.height);

    results.detections.forEach(detection => {
      drawRectangle(canvasCtx, detection.boundingBox);
    });
    canvasCtx.restore();
  };

  const drawRectangle = (canvasCtx, boundingBox) => {
    canvasCtx.beginPath();
    canvasCtx.rect(
      boundingBox.xCenter - boundingBox.width / 2,
      boundingBox.yCenter - boundingBox.height / 2,
      boundingBox.width,
      boundingBox.height
    );
    canvasCtx.lineWidth = 2;
    canvasCtx.strokeStyle = 'green';
    canvasCtx.stroke();
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>AK-EMOEATS</h1>
      
      </header>
      {permissionGranted ? (
        <>
          <div style={styles.webcamContainer}>
            <div style={styles.webcamInnerContainer}>
              {capturedImage ? (
                <img src={capturedImage} alt="Captured" style={styles.webcam} />
              ) : (
                <>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    style={styles.webcam}
                    mirrored={false}
                  />
                  <canvas ref={canvasRef} style={styles.canvas} />
                  {!isFaceDetected && (
                    <div style={styles.warningText}>
                      <p>Please ensure your face is visible in the camera to capture.</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div style={styles.buttonContainer}>
            <button
              style={styles.captureButton(isLoading)}
              onClick={capture}
              disabled={isLoading || !isFaceDetected}
            >
              {isLoading ? 'Loading...' : 'Capture'}
            </button>
          </div>
        </>
      ) : (
        <div>
          <p style={styles.permissionText}>
            Please grant permission to access the Mobile camera.
          </p>
          {permissionDenied && (
            <p style={styles.permissionError}>
              It seems you have denied the permission. Please enable it manually from your browser settings.
            </p>
          )}
        </div>
      )}

      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Select Your Preference</DialogTitle>
        <DialogContent>
          <RadioGroup
            value={preference}
            onChange={handlePreferenceChange}
          >
            <FormControlLabel value="VEG" control={<Radio />} label="Veg" />
            <FormControlLabel value="NON VEG" control={<Radio />} label="Non Veg" />
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="success">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    fontFamily: 'Poppins',
    textAlign: 'center',
    padding: '10px',
    boxSizing: 'border-box',
  },
  header: {
    marginBottom: '20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#3cba79',
  },
  subtitle: {
    fontSize: '20px',
    color: '#3cba79',
  },
  webcamContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '8px',
    boxSizing: 'border-box',
  },
  webcamInnerContainer: {
    width: '100%',
  
    height: '100%',
 
    border: '5px solid #3cba79', // Added green border
    borderRadius: '15px', // Rounded corners for border
    overflow: 'hidden', // Ensures webcam corners are rounded
    boxSizing: 'border-box',
    position: 'relative', // To position the canvas correctly
  },
  webcam: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  canvas: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  warningText: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    textAlign: 'center',
    width: '80%', // Adjust width as needed
  },
  buttonContainer: {
    position: 'fixed',
    bottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  captureButton: (isLoading) => ({
    width: '80px', // Increased size
    height: '80px', // Increased size
    borderRadius: '50%',
    backgroundColor: isLoading ? '#B0B0B0' : '#3cba79',
    border: 'none',
    cursor: isLoading ? 'not-allowed' : 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '16px', // Larger text size
  }),
  permissionText: {
    fontSize: '16px',
    color: '#000',
  },
  permissionError: {
    fontSize: '14px',
    color: 'red',
    marginTop: '10px',
  },
  '@media (max-width: 600px)': {
    title: {
      fontSize: '20px',
    },
    subtitle: {
      fontSize: '16px',
    },
    captureButton: {
      width: '60px',
      height: '60px',
      fontSize: '14px',
    },
  },
};

export default WebcamComponent;
