import React, { useState, useEffect } from 'react';
import { Typography, Box, Button, Snackbar, Alert, Link, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import GroupImage from './Group 1000000907.svg';
import { useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const OrderConfirmedComponent = ({ orderStatus, orderDetails }) => {
  const navigate = useNavigate();
  const { restaurantId, tableNo } = useParams();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [orderSnackbarOpen, setOrderSnackbarOpen] = useState(false);

  useEffect(() => {
    if (orderStatus) {
      setSnackbarOpen(true);
    }
  }, [orderStatus]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleOrderSnackbarClose = () => {
    setOrderSnackbarOpen(false);
  };

  const handleViewOrderClick = () => {
    setOrderSnackbarOpen(true);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        overflowY: 'auto',
        p: 2,
      }}
    >
       <Box sx={{ mt: { xs: 4, md: 8 }, width: '100%', textAlign: 'center' }}>
        <img src={GroupImage} alt="Group 1000000907" style={{ maxWidth: '100%', height: 'auto' }} />
      </Box>

      <Typography
  variant="h4"
  align="center"
  gutterBottom
  sx={{ mt: { xs: 2, md: 4 }, fontSize: { xs: '1.5rem', md: '2.125rem' } }}
>
  {orderStatus === 'success' ? 'Your order is confirmed!' : 'There was an error placing your order.'}
</Typography>
{orderStatus === 'success' && (
  <Typography
    variant="body1"
    align="center"
    sx={{ mt: { xs: 1, md: 2 }, fontSize: { xs: '0.5rem', md: '1.25rem' } }}
  >
    Please hang on with your beloved ones while we serve.
  </Typography>
)}

      {orderStatus === 'success' && (
        <Box>
         
          <Link
            component="button"
            variant="body2"
            onClick={handleViewOrderClick}
            sx={{ display: 'block', textAlign: 'center', mt: 2 }}
          >
            View Order
          </Link>
        </Box>
      )}
      <Button
        variant="contained"
        sx={{
          borderRadius: '10px',
          fontFamily: 'Poppins',
          backgroundColor: '#3cba79',
          color: '#fff',
          mb: { xs: 4, md: 8 },
          width: { xs: '100%', sm: '75%', md: '50%' },
          height: '48px',
          textTransform: 'none',
        }}
        onClick={() => navigate(`/${restaurantId}/${tableNo}`)}
      >
        Click here to recheck
      </Button>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert onClose={handleSnackbarClose} severity={orderStatus === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
          {orderStatus === 'success' ? 'Order placed successfully!' : 'Error placing order!'}
        </Alert>
      </Snackbar>

      <Snackbar
        open={orderSnackbarOpen}
        onClose={handleOrderSnackbarClose}
        autoHideDuration={null} // Keep open until manually closed
        message={
          <Box>
            <Typography variant="h6">Order Details</Typography>
            {orderDetails.map((item) => (
              <Typography key={item.menu} variant="body2">
                {item.menu}: {item.quantity} x {parseFloat(item.price.replace(/[^0-9.-]+/g, "")).toFixed(2)}
              </Typography>
            ))}
          </Box>
        }
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleOrderSnackbarClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        sx={{ maxWidth: '80%', width: 'auto' }} // Ensure responsiveness
      />
    </Box>
  );
};

export default OrderConfirmedComponent;
