import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import axios from 'axios';
import WebcamComponent from "./components/WebcamComponent";
import CapturedImageComponent from "./components/CapturedImageComponent";
import OrderConfirmedComponent from "./components/OrderConfirmedComponent";
import AllMenuDisplay from "./components/AllMenuDisplay";
import CartPage from "./components/CartPage";
import SelectionScreen from "./components/SelectionScreen";

const App = () => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({ line_items: [] });
  const [quantities, setQuantities] = useState({});
  const [description, setDescription] = useState("");
  const [menuItems, setMenuItems] = useState([]);
  const [suggestionId, setSuggestionId] = useState("");
  const [orderStatus, setOrderStatus] = useState(null);
  const [orderDetails, setOrderDetails] = useState([]);
  const [error, setError] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("Welcome!");

  const [restaurantId, setRestaurantId] = useState("");
  const [tableNo, setTableNo] = useState("");

  const handleAdd = (productId) => {
    const newQuantity = (quantities[productId] || 0) + 1;
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
    updateCart(productId, newQuantity);
  };

  const handleRemove = (productId) => {
    if (quantities[productId] > 0) {
      const newQuantity = quantities[productId] - 1;
      setQuantities((prevQuantities) => {
        const updatedQuantities = { ...prevQuantities, [productId]: newQuantity };
        if (newQuantity === 0) delete updatedQuantities[productId];
        return updatedQuantities;
      });
      updateCart(productId, newQuantity);
    }
  };

  const updateCart = (productId, quantity) => {
    setCart((prevCart) => {
      const existingItemIndex = prevCart.line_items.findIndex(
        (item) => item.menu === productId
      );
      let newLineItems;

      if (existingItemIndex > -1) {
        newLineItems = [...prevCart.line_items];
        if (quantity === 0) {
          newLineItems.splice(existingItemIndex, 1);
        } else {
          newLineItems[existingItemIndex].quantity = quantity;
        }
      } else {
        const itemToAdd = products.find((product) => product.menu === productId) || menuItems.find((product) => product.menu === productId);
        if (itemToAdd) {
          newLineItems = [...prevCart.line_items, { ...itemToAdd, quantity }];
        } else {
          newLineItems = [...prevCart.line_items];
        }
      }

      return { ...prevCart, line_items: newLineItems };
    });
  };

  const handleAddToCart = (productId, quantity) => {
    const newQuantity = quantity;
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
    updateCart(productId, newQuantity);
  };

  const formatDateTime = (date) => {
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    const day = padZero(date.getDate());
    const month = padZero(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = padZero(date.getMinutes());
    const seconds = padZero(date.getSeconds());
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const hour12 = hours % 12 || 12;
    const timezoneOffset = -date.getTimezoneOffset();
    const timezoneHours = Math.floor(Math.abs(timezoneOffset) / 60);
    const timezoneMinutes = Math.abs(timezoneOffset) % 60;
    const timezoneSign = timezoneOffset >= 0 ? '+' : '-';
    const formattedTimezone = `GMT${timezoneSign}${padZero(timezoneHours)}:${padZero(timezoneMinutes)}`;
    return `${day}/${month}/${year}, ${padZero(hour12)}:${minutes}:${seconds} ${ampm} ${formattedTimezone}`;
  };

  const fetchProducts = async (imageData, restaurantId, tableNo, preference) => {
    try {
      const response = await axios.post(
        'https://akemoeatsservice-er4v7pzexq-el.a.run.app/stage/getsuggestion', 
        {
          selfie: imageData,
          restaurantId,
          tableNo,
          datetime: formatDateTime(new Date()),
          preference,
        }
      );
  
      if (response.data.status === "suggested") {
        if (response.data.suggestions && response.data.suggestions.length > 0) {
          setProducts(response.data.suggestions);
          setDescription(response.data.mood);
          setSuggestionId(response.data.suggestionId);
        } else {
          setError("No suggestions available. Please try again later.");
        }
      } else if (response.data.detail === "No menu items found matching the criteria") {
        setError("No menu items found matching the criteria.");
      } else {
        setError("Unknown error occurred. Please try again later.");
      }
  
    } catch (error) {
      if (error.response && error.response.data && error.response.data.detail) {
        setError(error.response.data.detail);
      } else {
        console.error('Error fetching products:', error);
        setError("Network error. Please check your internet connection and try again.");
      }
    }
  };
  
  useEffect(() => {
    const fetchAllMenu = async (restaurantId) => {
      try {
        const response = await axios.get(
          `https://akemoeatsservice-er4v7pzexq-el.a.run.app/stage/getallmenu?customerId=${restaurantId}&skip=0&limit=10`
        );
        setMenuItems(response.data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };
    if (restaurantId) {
      fetchAllMenu(restaurantId);
    }
  }, [restaurantId]);

  useEffect(() => {
    const fetchWelcomeMessage = async (restaurantId,WelcomeMessage) => {
      try {
        const response = await axios.get(`https://usermanagmentservices-er4v7pzexq-el.a.run.app/getuserlistbyorgId?organizationId=${restaurantId}`);
        console.log(response.data)
        setWelcomeMessage(`Welcome to ${response.data[0].firstName} ${response.data[0].lastName} Cafe`);
        console.log(welcomeMessage)
      } catch (error) {
        console.error('Error fetching welcome message:', error);
      }
    };
    if (restaurantId) {
      fetchWelcomeMessage(restaurantId,welcomeMessage);
    }
    
  }, [restaurantId,welcomeMessage]);

  const placeOrder = async () => {
    try {
      const orderDetails = cart.line_items.map(item => ({
        menu: item.menu,
        price: item.price,
        quantity: item.quantity,
      }));

      if (orderDetails.length === 0) {
        setOrderStatus('error');
        console.error('Error placing order: No items in cart');
        return;
      }

      const payload = {
        suggestionId: suggestionId,
        restaurantId: restaurantId,
        tableNo: tableNo,
        orderDetails: orderDetails,
        datetime: formatDateTime(new Date()),
      };

      const response = await axios.post('https://akemoeatsservice-er4v7pzexq-el.a.run.app/stage/placeorder', payload);
      setCart({ line_items: [] });
      setQuantities({});
      setOrderStatus('success');
      setOrderDetails(orderDetails);
      return response.data;
    } catch (error) {
      setOrderStatus('error');
      console.error('Error placing order:', error);
    }
  };

  return (
    <div>
      <Routes>
        <Route path="/:restaurantId/:tableNo" element={<SelectionScreen setRestaurantId={setRestaurantId} setTableNo={setTableNo} WelcomeMessage={welcomeMessage} />} />
        <Route
          path="/:restaurantId/:tableNo/webcam"
          element={
            <WebcamComponent fetchProducts={fetchProducts} />
          }
        />
        <Route
          path="/:restaurantId/:tableNo/captured-image"
          element={
            <CapturedImageComponent
              products={products}
              description={description}
              handleAddToCart={handleAddToCart}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
              quantities={quantities}
              error={error}
              restaurantId={restaurantId} // Pass restaurantId as a prop
              tableNo={tableNo} // Pass tableNo as a prop
              welcomeMessage={welcomeMessage}

            />
          }
        />
        <Route
          path="/:restaurantId/:tableNo/order"
          element={<OrderConfirmedComponent orderStatus={orderStatus} orderDetails={orderDetails} />}
        />
        <Route
          path="/:restaurantId/:tableNo/allmenus"
          element={
            <AllMenuDisplay 
              handleAddToCart={handleAddToCart}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
              quantities={quantities}
              restaurantId={restaurantId}
              tableNo={tableNo}
            />
          }
        />
        <Route
          path="/:restaurantId/:tableNo/cart"
          element={
            <CartPage
              products={cart.line_items}
              quantities={quantities}
              placeOrder={placeOrder}
              handleAdd={handleAdd}
              handleRemove={handleRemove}
            />
          }
        />
      </Routes>
    </div>
  );
};

export default App;
