import React from 'react';
import Typography from '@mui/material/Typography';
import { Button, Card, CardContent, IconButton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const CartPage = ({ products, quantities, placeOrder, handleAdd, handleRemove }) => {
  const navigate = useNavigate();
  const { restaurantId, tableNo } = useParams();

  const handlePlaceOrder = async () => {
    await placeOrder();
    navigate(`/${restaurantId}/${tableNo}/order`);
  };

  const isCartEmpty = products.every((product) => quantities[product.menu] === 0);

  const parsePrice = (price) => parseFloat(price.replace(/[^0-9.-]+/g, ""));

  const totalPrice = products.reduce((total, product) => {
    const quantity = quantities[product.menu] || 0;
    const price = parsePrice(product.price) || 0;
    return total + (quantity * price);
  }, 0);

  return (
    <div style={{ marginTop: "20px" }}>
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ fontFamily: "Poppins", marginBottom: "20px", marginLeft: "120px" }}
      >
        Your Cart ({products.reduce((total, product) => total + (quantities[product.menu] || 0), 0)} items)
      </Typography>

      {isCartEmpty ? (
        <Typography
          variant="body1"
          sx={{ fontFamily: "Poppins", marginBottom: "20px", textAlign: "center" }}
        >
          Your cart is empty!
        </Typography>
      ) : (
        products.map(
          (product) =>
            quantities[product.menu] > 0 && (
              <Card
                key={product.menu}
                sx={{
                  mb: 2,
                  background: "#e9f5f0",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                  }}
                >
                  <img
                    src={product.image}
                    alt={product.menu}
                    style={{
                      width: 70,
                      height: 70,
                      marginRight: 10,
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop if fallback image is also not found
                      e.target.src = 'https://storage.googleapis.com/emoeatsmenuimages/imagenotfound.jfif';
                    }}
                  />
                  <div style={{ flex: 1, marginLeft: '50px' }}>
                    <Typography variant="body1">{product.menu}</Typography>
                    <Typography>Quantity: {quantities[product.menu]}</Typography>
                    <Typography>Price: ₹{parsePrice(product.price).toFixed(2)}</Typography>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <IconButton
                        onClick={() => handleRemove(product.menu)}
                        size="small"
                        sx={{ backgroundColor: '#e1e1e1', color: 'white', marginRight: 1 }}
                      >
                        <RemoveIcon />
                      </IconButton>
                      <Typography variant="body1" sx={{ mx: '5px' }}>
                        {quantities[product.menu]}
                      </Typography>
                      <IconButton
                        onClick={() => handleAdd(product.menu)}
                        size="small"
                        sx={{ backgroundColor: '#3cba79', color: 'white', marginLeft: 1 }}
                      >
                        <AddIcon />
                      </IconButton>
                    </div>
                  </div>
                </CardContent>
              </Card>
            )
        )
      )}

      {!isCartEmpty && (
        <Typography
          variant="h6"
          sx={{ fontFamily: "Poppins", marginTop: "20px", textAlign: "center" }}
        >
          Total Price: ₹{totalPrice.toFixed(2)}
        </Typography>
      )}

      <Button
        variant="contained"
        sx={{
          p: "1",
          height: "58px",
          width: "375px",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: isCartEmpty ? '#B0B0B0' : '#3cba79',
          color: "#fff",
          position: "fixed",
          bottom: "20px",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 999,
          boxShadow: "0px -2px 5px rgba(0, 0, 0, 0.1)",
          cursor: isCartEmpty ? 'not-allowed' : 'pointer',
        }}
        onClick={handlePlaceOrder}
        disabled={isCartEmpty} // Disable the button if the cart is empty
      >
        <div style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
          Place Order
        </div>
      </Button>
    </div>
  );
};

export default CartPage;
