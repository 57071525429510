import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

const SelectionScreen = ({ setRestaurantId, setTableNo,WelcomeMessage }) => {
  const navigate = useNavigate();
  const { restaurantId, tableNo } = useParams();

  React.useEffect(() => {
    setRestaurantId(restaurantId);
    setTableNo(tableNo);
  }, [restaurantId, tableNo, setRestaurantId, setTableNo]);

  const handleAiSuggestionClick = () => {
    navigate(`/${restaurantId}/${tableNo}/webcam`);
  };

  const handleManualSelectionClick = () => {
    navigate(`/${restaurantId}/${tableNo}/allmenus`);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
    >
      <Typography variant="h4" mb={4}>
      {WelcomeMessage}
      </Typography>
      <Typography variant="h6" mb={4}>
        How would you like to proceed?
      </Typography>
      <Button
        variant="contained"
        color="success"
        onClick={handleAiSuggestionClick}
        sx={{ mb: 2, width: '200px' }}
      >
        AI Suggestions
      </Button>
      <Button
        variant="contained"
        color="success"
        onClick={handleManualSelectionClick}
        sx={{ width: '200px' }}
      >
        Manual Selection
      </Button>
    </Box>
  );
};

export default SelectionScreen;
