import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Card, CardContent, Box, Snackbar, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const ProductImage = ({ product }) => {
  const hasImage = product && product.image;
  const productName = product && product.menu;
  const backgroundColor = getRandomColor();
  const fallbackImage = 'https://storage.googleapis.com/emoeatsmenuimages/imagenotfound.jfif';

  return (
    <Box
      sx={{
        width: 70,
        height: 70,
        marginRight: 1,
        marginBottom: 5,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: hasImage ? 'transparent' : backgroundColor,
        overflow: 'hidden',
      }}
    >
      {hasImage ? (
        <img
          src={product.image}
          alt={product.menu}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            objectFit: 'cover',
          }}
          onError={(e) => {
            e.target.onerror = null; // Prevents infinite loop if fallback image is also not found
            e.target.src = fallbackImage;
          }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            color: '#fff',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          <Typography variant="h6">
            {productName ? productName.charAt(0) : ''}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const CapturedImageComponent = ({
  handleAddToCart,
  products,
  description,
  handleAdd,
  handleRemove,
  quantities,
  error,
  restaurantId,
  tableNo,
  welcomeMessage,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { imageData } = location.state || {};
  const [notificationOpen, setNotificationOpen] = useState(true);

  const handleAddButtonClick = (productId) => {
    const newQuantity = 1; // Start with a quantity of 1 when adding for the first time
    handleAddToCart(productId, newQuantity);
  };

  const addToCart = () => {
    Object.entries(quantities).forEach(([productId, quantity]) => {
      handleAddToCart(productId, quantity);
    });
    navigate(`/${restaurantId}/${tableNo}/cart`);
  };

  const showAllMenu = () => {
    navigate(`/${restaurantId}/${tableNo}/allmenus`);
  };

  const handleNotificationClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotificationOpen(false);
  };

  return (
    <div style={{ margin: '20px', textAlign: 'center', minHeight: 'calc(100vh - 100px)', paddingBottom: '100px' }}>
      <Typography variant="h5" fontWeight="bold" sx={{ fontFamily: 'Poppins', display: 'inline-block', mb: 4 }}>
        {welcomeMessage}
      </Typography>

      <div>
        {imageData && (
          <img
            src={imageData}
            alt="Captured"
            style={{
              borderRadius: '10px 80px 10px 80px',
              marginBottom: '20px',
            }}
          />
        )}
      </div>

      {error ? (
        <Typography
          variant="body1"
          fontWeight="500"
          sx={{
            fontFamily: 'Poppins',
            margin: '0 10px',
            display: 'block',
            textAlign: 'justify',
            color: 'red',
          }}
        >
          {error}
        </Typography>
      ) : (
        <>
          <Typography
            variant="body1"
            fontWeight="500"
            sx={{
              fontFamily: 'Poppins',
              margin: '0 10px',
              display: 'block',
              textAlign: 'justify',
            }}
          >
            {description}
          </Typography>
          <Button
            variant="contained"
            sx={{
              borderRadius: '10px',
              fontFamily: 'Poppins',
              backgroundColor: '#3cba79',
              color: '#fff',
              margin: '20px auto',
              width: '100%',
              height: '48px',
              textTransform: 'none',
            }}
            onClick={() => navigate(`/${restaurantId}/${tableNo}`)}
          >
            Click here to recheck
          </Button>

          <div style={{ maxWidth: '100%', margin: '0 auto 20px' }}>
            <Typography variant="h5" fontWeight="bold" sx={{ fontFamily: 'Poppins', margin: '0 0px', width: '100%' }}>
              AI Suggested Menu
            </Typography>
          </div>

          {products.map((product) => (
            <Card
              key={product.menu}
              sx={{
                mb: 2,
                background: '#e9f5f0',
                display: 'flex',
                borderRadius: '10px',
                margin: '10px -20px',
              }}
            >
              <CardContent sx={{ flex: '1', display: 'flex', alignItems: 'center' }}>
                <ProductImage product={product} />
              </CardContent>
              <CardContent
                sx={{
                  flex: '2',
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'left',
                }}
              >
                <Typography>{product.menu}</Typography>
                <Typography>
                  <strong>{product.price}</strong>
                </Typography>
              </CardContent>

              <CardContent
                sx={{
                  flex: '2',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 10,
                  mr: 4,
                }}
              >
                {quantities[product.menu] ? (
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '0px',
                      borderRadius: '4px',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '-8px',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="info"
                      size="small"
                      onClick={() => handleRemove(product.menu)}
                      sx={{
                        p: '5px',
                        minWidth: '24px',
                        minHeight: '24px',
                        background: '#e1e1e1',
                        color: 'white',
                      }}
                    >
                      <RemoveIcon />
                    </Button>
                    <Typography variant="body1" sx={{ mx: '5px', padding: '4px' }}>
                      {quantities[product.menu]}
                    </Typography>
                    <Button
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={() => handleAdd(product.menu)}
                      sx={{
                        p: '5px',
                        minWidth: '24px',
                        minHeight: '24px',
                        background: '#3cba79',
                        color: 'white',
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleAddButtonClick(product.menu)}
                    sx={{
                      p: '5px',
                      minWidth: '24px',
                      minHeight: '24px',
                      background: '#3cba79',
                      color: 'white',
                    }}
                  >
                    <AddIcon />
                    <Typography variant="body1" sx={{ mx: '5px', color: 'white' }}>
                      Add
                    </Typography>
                  </Button>
                )}
              </CardContent>
            </Card>
          ))}
        </>
      )}

      <Button
        variant="contained"
        onClick={addToCart}
        sx={{
          position: 'fixed',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 999,
          boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          fontFamily: 'Poppins',
          backgroundColor: '#3cba79',
          color: '#fff',
          width: '375px',
          height: '58px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {Object.values(quantities).reduce((total, qty) => total + qty, 0)}{' '}
          item
          {Object.values(quantities).reduce((total, qty) => total + qty, 0) !== 1
            ? 's'
            : ''}{' '}
          added
        </div>
        <div>View Cart</div>
      </Button>

      <Button
        variant="contained"
        sx={{
          borderRadius: '10px',
          fontFamily: 'Poppins',
          backgroundColor: '#3cba79',
          color: '#fff',
          margin: '20px auto',
          width: '100%',
          height: '48px',
          textTransform: 'none',
        }}
        onClick={showAllMenu}
      >
        Choose Menu Yourself
      </Button>

      <Snackbar
        open={notificationOpen}
        autoHideDuration={6000}
        onClose={handleNotificationClose}
      >
        <Alert onClose={handleNotificationClose} severity="info" sx={{ width: '100%' }}>
          <Typography variant="body1" sx={{ fontFamily: 'Poppins' }}>
            Here you can find details about our suggested menu. We recommend these items based on various factors
            including current trends, customer preferences, and nutritional value. Feel free to explore and enjoy!
          </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CapturedImageComponent;
