import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Card,
  CardContent,
  Box,
  TextField,
  TablePagination,
  Tabs,
  Tab,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// Function to generate a random color
const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const ProductImage = ({ product }) => {
  const hasImage = product && product.image;
  const productName = product && product.menu;
  const backgroundColor = getRandomColor();
  const fallbackImage = 'https://storage.googleapis.com/emoeatsmenuimages/imagenotfound.jfif';

  return (
    <Box
      sx={{
        width: 70,
        height: 70,
        marginRight: 1,
        marginBottom: 5,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: hasImage ? 'transparent' : backgroundColor,
        overflow: 'hidden',
      }}
    >
      {hasImage ? (
        <img
          src={product.image}
          alt={product.menu}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            objectFit: 'cover',
          }}
          onError={(e) => {
            e.target.onerror = null; // Prevents infinite loop if fallback image is also not found
            e.target.src = fallbackImage;
          }}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            borderRadius: '8px',
            color: '#fff',
            fontSize: '24px',
            fontWeight: 'bold',
          }}
        >
          <Typography variant="h6">
            {productName ? productName.charAt(0) : ''}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const AllMenuDisplay = ({
  handleAddToCart,
  handleAdd,
  handleRemove,
  quantities,
  restaurantId,
  tableNo,
}) => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const fetchAllMenu = async () => {
      try {
        const response = await axios.get(
          `https://akemoeatsservice-er4v7pzexq-el.a.run.app/stage/getallmenu?customerId=${restaurantId}&skip=0&limit=100`
        );
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching menu items:', error);
      }
    };
    fetchAllMenu();
  }, [restaurantId]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setPage(0); // Reset to first page on new search
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page on rows per page change
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPage(0); // Reset to first page when changing tabs
  };

  const filteredProducts = products.filter((product) =>
    product.menu.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const categories = [...new Set(products.map((product) => product.category))];
  const paginatedProducts = filteredProducts.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const addToCart = () => {
    navigate(`/${restaurantId}/${tableNo}/cart`);
  };

  const handleAddButtonClick = (productId) => {
    const newQuantity = 1; // Start with a quantity of 1 when adding for the first time
    handleAddToCart(productId, newQuantity);
  };

  return (
    <div
      style={{
        margin: '20px',
        textAlign: 'center',
        minHeight: 'calc(100vh - 100px)',
        paddingBottom: '100px',
      }}
    >
      <TextField
        label="Search Menu"
        variant="outlined"
        value={searchQuery}
        onChange={handleSearchChange}
        sx={{
          mb: 3,
          width: '100%',
          '& .MuiOutlinedInput-root': {
            borderRadius: '15px', // Rounded corners
          },
        }}
      />

      {/* Category Tabs */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        sx={{ mb: 3 }}
      >
        {categories.map((category, index) => (
          <Tab key={index} label={category} />
        ))}
      </Tabs>

      {/* Display Menu Items based on selected category */}
      {categories.map((category, index) => (
        <div
          role="tabpanel"
          hidden={selectedTab !== index}
          key={index}
          style={{ width: '100%' }}
        >
          {selectedTab === index && (
            <>
              {paginatedProducts
                .filter((product) => product.category === category)
                .map((product) => (
                  <Card
                    key={product.menu}
                    sx={{
                      mb: 2,
                      background: '#e9f5f0',
                      display: 'flex',
                      borderRadius: '10px',
                      margin: '10px -20px',
                    }}
                  >
                    <CardContent
                      sx={{ flex: '1', display: 'flex', alignItems: 'center' }}
                    >
                      <ProductImage product={product} />
                    </CardContent>
                    <CardContent
                      sx={{
                        flex: '2',
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'left',
                      }}
                    >
                      <Typography>{product.menu}</Typography>
                      <Typography>
                        <strong>{product.price}</strong>
                      </Typography>
                    </CardContent>

                    <CardContent
                      sx={{
                        flex: '2',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mt: 10,
                        mr: 4,
                      }}
                    >
                      {quantities[product.menu] ? (
                        <div
                          style={{
                            backgroundColor: 'white',
                            padding: '0px',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: '-8px',
                          }}
                        >
                          <Button
                            variant="contained"
                            color="info"
                            size="small"
                            onClick={() => handleRemove(product.menu)}
                            sx={{
                              p: '5px',
                              minWidth: '24px',
                              minHeight: '24px',
                              background: '#e1e1e1',
                              color: 'white',
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                          <Typography
                            variant="body1"
                            sx={{ mx: '5px', padding: '4px' }}
                          >
                            {quantities[product.menu]}
                          </Typography>
                          <Button
                            variant="contained"
                            color="success"
                            size="small"
                            onClick={() => handleAdd(product.menu)}
                            sx={{
                              p: '5px',
                              minWidth: '24px',
                              minHeight: '24px',
                              background: '#3cba79',
                              color: 'white',
                            }}
                          >
                            <AddIcon />
                          </Button>
                        </div>
                      ) : (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => handleAddButtonClick(product.menu)}
                          sx={{
                            p: '5px',
                            minWidth: '24px',
                            minHeight: '24px',
                            background: '#3cba79',
                            color: 'white',
                          }}
                        >
                          <AddIcon />
                          <Typography
                            variant="body1"
                            sx={{ mx: '5px', color: 'white' }}
                          >
                            Add
                          </Typography>
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                ))}

           
            </>
          )}
        </div>
      ))}

      <Button
        variant="contained"
        onClick={addToCart}
        sx={{
          position: 'fixed',
          bottom: '20px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 999,
          boxShadow: '0px -2px 5px rgba(0, 0, 0, 0.1)',
          borderRadius: '10px',
          fontFamily: 'Poppins',
          backgroundColor: '#3cba79',
          color: '#fff',
          width: '375px',
          height: '58px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {Object.values(quantities).reduce((total, qty) => total + qty, 0)}{' '}
          item
          {Object.values(quantities).reduce((total, qty) => total + qty, 0) !==
          1
            ? 's'
            : ''}{' '}
          added
        </div>
        <div>View Cart</div>
      </Button>
    </div>
  );
};

export default AllMenuDisplay;
